import { extendTheme } from "@chakra-ui/react";

const colors = {
  gray: {
    200: "#E2E2E2",
  },
  yellow: {
    500: "#ffcb41",
  },
};

const theme = extendTheme({
  colors,
  config: { initialColorMode: "dark", useSystemColorMode: false },
  fonts: {
    html: "Brandon",
    body: "Brandon",
  },
});

export default theme;
